import { Role } from '@/helpers';

export default [
    {
        path: 'mylessons',
        name: 'MyLessons',
        component: () => import(/* webpackChunkName: "teacher-lesson-calendar" */ '@/pages/Teacher/LessonsCalendar'),
        meta: {
            authorize: [Role.teacher],
            sidebar: true,
            icon: 'fa-calendar',
            title: 'Мои уроки',
        },
    },
    {
        path: 'mystudents',
        name: 'MyStudents',
        component: () => import(/* webpackChunkName: "teacher-lesson-calendar" */ '@/pages/Teacher/StudentsList.vue'),
        meta: {
            authorize: [Role.teacher],
            sidebar: true,
            icon: 'fa-user',
            title: 'Мои студенты',
        },
    },
    {
        path: 'tprofile',
        name: 'tProfile',
        component: () => import(/* webpackChunkName: "teacher-profile-container" */ '@/pages/Teacher/TeacherProfileContainer.vue'),
        meta: {
            authorize: [Role.teacher],
            sidebar: true,
            icon: 'fa-user',
            title: 'Мои данные',
        },
    },
    {
        path: 'my-stat',
        name: 'MyStat',
        component: () => import(/* webpackChunkName: "teacher-stats" */ '@/pages/Teacher/TeacherStats'),
        meta: {
            authorize: [Role.teacher],
            sidebar: true,
            icon: 'fa-bar-chart',
            title: 'Моя статистика',
        },
    },
    {
        path: 'my-salary',
        name: 'MySalary',
        component: () => import(/* webpackChunkName: "teacher-profile-salary" */ '@/pages/Teacher/TeacherProfileSalary'),
        meta: {
            authorize: [Role.teacher],
            sidebar: true,
            icon: 'fa-dollar',
            title: 'Моя зарплата',
        },
    },
]