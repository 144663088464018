import axios from 'axios'

export default {
    namespaced: true,
    state: {
        studentList: null,
        studentDetail: null,
        studentListMeta: null,
        studentLichessDetail: null
    },
    mutations: {
        setStudentList(state, data){
            state.studentList = data
        },
        setStudentDetail(state, data){
            state.studentDetail = data
        },
        setStudentLichessDetail(state, data){
            state.studentLichessDetail = data
        },
        setListMeta(state, data) {
            state.studentListMeta = data
        }
    },
    getters: {
        getStudentList(state){
            return state.studentList
        },
        getStudentDetail(state){
            return state.studentDetail
        },
        getPageMeta(state) {
            return state.studentListMeta
        },
        getStudentLichessDetail(state) {
            return state.studentLichessDetail
        }
    },
    actions: {
        async loadStudentList({commit, getters}, params = null) {
            const response = await this.$axios.get('crm/students/all', {params: { ...params }});
            commit('setStudentList', response.data.data);
            commit('setListMeta', response.data.meta);
        },
        async fetchStudentOptions({ commit }, payload) {
            try {
                const response = await this.$axios.get('/crm/students/autocomplete', {
                    params: payload
                });
                return response.data.data;
            } catch (ex) {
                console.log(ex);
            }
            return [];
        },
        async loadStudentDetail({commit, getters}, id) {
            const response = await this.$axios.get(`api/students/${id}`)
            commit('setStudentDetail', response.data);
        },
        async loadStudentLichessDetail({commit, getters}, id) {
            const response = await this.$axios.get(`crm/students/${id}/lichess`)
            commit('setStudentLichessDetail', response.data);
        },
        async updateStudent({commit}, data) {
            const response = await this.$axios.post(`/api/students/update`, { ...data });
            return response.data;
        },
        async updateStudentLichess({commit}, payload) {
            const response = await this.$axios.post(`crm/students/${payload.id}/lichess/update`, { ...payload.params });
            return response.data;
        },
        async registerStudentLichess({commit}, payload) {
            const response = await this.$axios.post(`crm/students/${payload.id}/lichess/register`, { ...payload.params });
            return response.data;
        },
        async createStudent({commit}, data) {
            const response = await this.$axios.post(`/api/students/create`, { ...data });
            return response.data;
        },
        async findStudents({commit}, searchWord) {
            const response = await this.$axios.get(`/crm/students/all`, {
                params: {
                    search: searchWord
                }
            });
            commit('setStudentList', response.data.data)
            commit('setListMeta', response.data.meta)
        },
        async deleteRecord({dispatch}, id) {
            const response = await this.$axios.post('/api/students/remove', { id })
            dispatch('loadStudentList')
        },
    }
}