import { Role } from '@/helpers';

export default [
    {
        path: 'teachers',
        name: 'TeacherList',
        component: () => import(/* webpackChunkName: "teacherList" */ '@/pages/Admin/TeacherList.vue'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            sidebar: true,
            icon: 'fa-user-circle-o',
            title: 'Преподаватели',
        },
    },
    {
        path: 'teachers/add',
        name: 'TeacherAdd',
        component: () => import(/* webpackChunkName: "teacherList" */ '@/pages/Admin/TeacherCreate.vue'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
        },
    },
    {
        path: 'teachers/:id',
        name: 'TeacherDetail',
        component: () => import(/* webpackChunkName: "teacherList" */ '@/pages/Admin/TeacherProfileContainer.vue'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
        },
    },
]