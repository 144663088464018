<template>
  <b-list-group class="listGroup thin-scroll">
    <b-list-group-item class="listGroupItem bg-primary-light">
      <span class="notificationIcon thumb-sm">
        <img
          class="rounded-circle"
          src="../../../assets/people/a2.jpg"
          alt="..."
        />
        <i class="status status-bottom bg-success" />
      </span>
      <time class="text-link help float-right">10 sec ago</time>
      <h6 class="m-0 fw-bold mb-1">Chris Gray</h6>
      <p class="deemphasize text-ellipsis m-0">
        Hey! What&apos;s up? So many times since we
      </p>
    </b-list-group-item>
    <b-list-group-item class="listGroupItem">
      <span class="notificationIcon thumb-sm">
        <img
          class="rounded-circle"
          src="../../../assets/people/a5.jpg"
          alt="..."
        />
        <i class="status status-bottom bg-success" />
      </span>
      <time class="text-link help float-right">2 min ago</time>
      <h6 class="m-0 mb-1">Jamey Brownlow</h6>
      <p class="deemphasize text-ellipsis m-0">
        Good news coming tonight. Seems they agreed to proceed
      </p>
    </b-list-group-item>
    <b-list-group-item class="listGroupItem">
      <span class="notificationIcon thumb-sm">
        <img
          class="rounded-circle"
          src="../../../assets/people/a1.jpg"
          alt="..."
        />
        <i class="status status-bottom bg-warning" />
      </span>
      <time class="text-link help float-right">9 min ago</time>
      <h6 class="m-0 mb-1">Livia Walsh</h6>
      <p class="deemphasize text-ellipsis m-0">
        Check out my latest email plz!
      </p>
    </b-list-group-item>
    <b-list-group-item class="listGroupItem">
      <span class="notificationIcon thumb-sm">
        <img
          class="rounded-circle"
          src="../../../assets/people/a6.jpg"
          alt="..."
        />
        <i class="status status-bottom bg-danger" />
      </span>
      <time class="text-link help float-right">12:56 AM</time>
      <h6 class="m-0 mb-1">Jaron Fitzroy</h6>
      <p class="deemphasize text-ellipsis m-0">What about summer break?</p>
    </b-list-group-item>
    <b-list-group-item class="listGroupItem">
      <span class="notificationIcon thumb-sm">
        <img
          class="rounded-circle"
          src="../../../assets/people/a4.jpg"
          alt="..."
        />
        <i class="status status-bottom bg-gray-light" />
      </span>
      <time class="text-link help float-right">Yesterday</time>
      <h6 class="m-0 mb-1">Mike Lewis</h6>
      <p class="deemphasize text-ellipsis m-0">
        Just ain&apos;t sure about the weekend now. 90% I&apos;ll make it.
      </p>
    </b-list-group-item>
    <b-list-group-item class="listGroupItem">
      <span class="notificationIcon thumb-sm">
        <img
          class="rounded-circle"
          src="../../../assets/people/a6.jpg"
          alt="..."
        />
        <i class="status status-bottom bg-success" />
      </span>
      <time class="text-link help float-right">Apr 23</time>
      <h6 class="m-0 mb-1">Freda Edison</h6>
      <p class="deemphasize text-ellipsis m-0">
        Hey what&apos;s up? Me and Monica going for a lunch somewhere. Wanna
        join?
      </p>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  name: "Messages",
};
</script>

<style src="./ListGroup.scss" lang="scss" />
