import Vue from "vue";
import { instance } from '../i18n'
/**
 * Стейт для общих настроек, выпадающие списки и т.д
 * */

export default {
    namespaced: true,
    state: {
        constants: {
            student_experience: [],
            lesson_types: [],
            lesson_statuses: [],
            timezones: [],
            roles: [],
            notification_ways: ['telegram', 'whatsapp', 'email'],
            lesson_durations: [25],
            gender_list: [],
            language_list: [],
            location_list: [],
            teacher_degree: [],
            countries: [],
            student_statuses:[],
            lead_statuses: {},
            lead_providers: {},
            countries_codes: {},
            countriesCodesMap: {},
            age_groups: {}
        }
    },
    mutations: {
        setConstants(state, payload) {
            if (payload.countries_codes) {
                for (const item of payload.countries_codes) {
                    if (!payload.countriesCodesMap) {
                        payload.countriesCodesMap = {
                            [item.value]: item.text,
                        }
                    } else {
                        payload.countriesCodesMap[item.value] = item.text
                    }
                }
            }
            state.constants = instance.translateConstants(payload)
        }
    },
    getters: {
        getExperienceList(state) {
            return state.constants.student_experience;
        },
        getLeadStatuses(state) {
            return state.constants.lead_statuses;
        },
        getAgeGroups(state) {
            return state.constants.age_groups;
        },
        getLeadProviders(state) {
            return state.constants.lead_providers;
        },
        getStudentStatusList(state) {
            return state.constants.student_statuses;
        },
        getLessonTypeList(state) {
            return state.constants.lesson_types;
        },
        getLessonStatusList(state) {
            return state.constants.lesson_statuses;
        },
        getNotificationWay(state) {
            return state.constants.notification_ways;
        },
        getLessonDurations(state) {
            return state.constants.lesson_durations;
        },
        getAllRoles(state) {
            return state.constants.roles;
        },
        getAllTimezones(state) {
            return state.constants.timezones;
        },
        getGenderList(state) {
            return state.constants.gender_list;
        },
        getLanguageList(state) {
            return state.constants.language_list;
        },
        getLocationList(state) {
            return state.constants.location_list;
        },
        getTeacherDegree(state) {
            return state.constants.teacher_degree
        },
        getCountries(state) {
            return state.constants.countries
        },
        getCountriesCodes(state) {
            return state.constants.countries_codes
        },
        getCountriesCodesMap(state) {
            return state.constants.countriesCodesMap
        }
    },
    actions: {
        // TODO ???
        notifyUser({ commit }, response) {
            if (response.data && response.data.errors) {
                for (const error_message in response.data.errors) {
                    Vue.toasted.show(`Ошибка ${response.data.errors[error_message]}`, {
                        position:'bottom-right',
                        type: 'error',
                        action: {
                            text: 'Закрыть',
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            }
                        }
                    })
                }
            }
            if (response.data.status === 'success') {
                Vue.toasted.show('Запрос успешно выполнен', {
                    position:'bottom-right',
                    type: 'success',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        }
                    }
                })
            }
        }
    }
}