import { Role } from '@/helpers';

export default [
    {
        path: 'users',
        name: 'UsersPage',
        component: () => import(/* webpackChunkName: "usersPage" */ '@/pages/Admin/Users.vue'),
        meta: {
            authorize: [Role.admin, Role.superadmin],
            sidebar: true,
            icon: 'fa-users',
            title: 'Пользователи',
        },
    },
    {
        path: 'users/add',
        name: 'UserAdd',
        component: () => import(/* webpackChunkName: "userDetailPage" */ '@/pages/Admin/UserDetail.vue'),
        meta: {
            authorize: [Role.admin, Role.superadmin],
            icon: 'flaticon-settings-5',
            title: 'Добавить пользователя',
        },
    },
    {
        path: 'users/:id',
        name: 'UserDetail',
        component: () => import(/* webpackChunkName: "userDetailPage" */ '@/pages/Admin/UserDetail.vue'),
        meta: {
            authorize: [Role.admin, Role.superadmin],
            icon: 'flaticon-settings-5',
            title: 'Пользователи',
        },
    },
]