export default {
    namespaced: true,
    state: {
        paymentList: null,
        paymentDetails: null,
        pageMeta: {
            total: 0,
            per_page: 100
        },
        stats: null,
    },
    getters: {
        getList(state) {
            return state.paymentList;
        },
        getDetails(state) {
            return state.paymentDetails;
        },
        getPageMeta(state) {
            return state.pageMeta
        },
        getPaymentsStats(state) {
            return state.stats
        }
    },
    mutations: {
        setList(state, payload) {
            state.paymentList = payload;
            // state.leads.push(...payload)
        },
        setDetails(state, payload) {
            state.paymentDetails = payload;
        },
        setPageMeta(state, payload) {
            state.pageMeta = payload
        },
        setStats(state, payload) {
            state.stats = payload
        }
    },
    actions: {
        async fetchData({commit}, params=null) {
            const result = await this.$axios.get(`/api/payments`, {params: {...params}});
            commit('setList', result.data.data);
            commit('setPageMeta', result.data.meta)
            commit('setStats', result.data.stats)
        },
        async findData({ commit }, searchWord) {
            const response = await this.$axios.get(`/api/payments`, {
                params: {
                    ...this.$route.query,
                    search: searchWord
                }
            });
            commit('setList', response.data.data)
            commit('setPageMeta', response.data.meta)
        },
        async loadPaymentDetails({ commit }, leadId) {
            const data = await this.$axios.get(`/api/leads/${leadId}`);
            if (data.status !== 200) {
                commit('setDetails', null);
            }
            commit('setLeadDetails', data.data);
        },
        async refundItem({commit}, payload) {
            const item = payload.item
            const refundData = {
                transaction_id: item.transaction_id,
                refund_sum: payload.payload.refund_sum,
                note: payload.payload.note,
                qty: payload.payload.quantity
            }
            const { data } = await this.$axios.post(`/api/refund`, refundData);

            if (Array.isArray(data)) {
                throw new Error('Неверный формат ответа')
            }

            if (data && data.status) {
                if (data.status === 'succeeded') {
                    return data;
                }
                if (data.status === 'canceled') {
                    throw new Error('платеж отменен, истекло время на принятие платежа или платеж был отклонен')
                }
                if (data.error) {
                    throw new Error('Ошибка ' + data.error)
                }
            }

            if (data && data.error) {
                if (data.data && data.data.message) {
                    throw new Error(data.data.message)
                }
                if (typeof data.error === 'string') {
                    throw new Error(data.error)
                }
                console.error(data)
                throw new Error("ошибка записана в лог")
            }

            throw new Error('Необработанная ошибка');
            // $transaction_id = $request->input('transaction_id');
            // $amount = $request->input('amount');
            // $note = $request->input('note');



            // commit('setList', result.data.data);
            // commit('setPageMeta', result.data.meta)
        },
    }
}