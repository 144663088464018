import { Role } from '@/helpers';

export default [
    {
        path: 'stats',
        name: 'StatsList',
        component: () => import(/* webpackChunkName: "teacherList" */ '@/pages/Admin/Stats/StatsListTab.vue'),
        redirect: 'stats/school',
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            sidebar: true,
            icon: 'fa-bar-chart',
            title: 'Статистика',
        },
    },
    {
        path: 'stats/:id',
        component: () => import('@/pages/Admin/Stats/StatsListTab.vue'),
        children: [
            {
                path: 'school',
                component: () => import('@/pages/Admin/Stats/StatsListSchool.vue'),
            },
            {
                path: 'teachers',
                component: () => import('@/pages/Admin/Stats/StatsListTeachers.vue'),
            },
        ],
    },
]