import Vue from 'vue';
import Vuex from 'vuex';

import layout from './layout';
import user from './user';
import leads from './leads';
import lessons from './lessons';
import students from './students';
import settings from './settings';
import teacher from './teacher';
import common from './common';
import users from './users';
import payments from './payments';
import scheduler from './scheduler';
import reviews from './reviews';
import blogArticle from './blogArticle';
import blogCategory from './blogCategory';
import lessonsSearch from "@/store/lessonsSearch";
import logs from "@/store/logs";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    layout,
    user,
    leads,
    lessons,
    students,
    settings,
    teacher,
    common,
    users,
    payments,
    scheduler,
    reviews,
    blogArticle,
    blogCategory,
    lessonsSearch,
    logs,
  },
  state: {
    openedEmailMessage: {
      campaignId: null
    },
  },
  mutations: {
    setEmailMessageCampaign(state, payload) {
      state.openedEmailMessage.campaignId = payload
    }
  }
});
