import { Role } from '@/helpers';

export default [
    {
        path: 'settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "settingsPage" */ '@/pages/Admin/Settings.vue'),
        meta: {
            authorize: [Role.admin, Role.superadmin],
            sidebar: true,
            icon: 'fa-cog',
            title: 'Настройки',
        },
    },
]