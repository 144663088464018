<template>
  <div :class="[{root: true, sidebarClose, sidebarStatic}, 'sing-dashboard']">
    <Sidebar/>
    <div class="wrap">
      <Header/>
      <v-touch class="content" @swipe="handleSwipe" :swipe-options="{direction: 'horizontal'}">
        <!--      <breadcrumb-history></breadcrumb-history>-->
        <transition name="router-animation">
          <router-view/>
        </transition>
        <!--&lt;!&ndash;      <footer class="contentFooter">&ndash;&gt;-->
        <!--&lt;!&ndash;        Sing App Vue Dashboard Free Template - Made by <a href="https://flatlogic.com" rel="nofollow noopener noreferrer" target="_blank">Flatlogic</a>&ndash;&gt;-->
        <!--&lt;!&ndash;      </footer>&ndash;&gt;-->
      </v-touch>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers, mapActions, mapGetters} from 'vuex';
const { mapState } = createNamespacedHelpers('layout');

import Sidebar from '@/components/Sidebar/Sidebar';
import Header from '@/components/Header/Header';
import BreadcrumbHistory from '@/components/BreadcrumbHistory/BreadcrumbHistory';

import './Layout.scss';

export default {
  name: 'Layout',
  components: {Sidebar, Header, BreadcrumbHistory},
  methods: {
    ...mapActions(
        {
          switchSidebar: 'layout/switchSidebar',
          handleSwipe: 'layout/handleSwipe',
          changeSidebarActive: 'layout/changeSidebarActive',
          toggleSidebar: 'layout/toggleSidebar'
        }
    ),
    handleWindowResize() {
      const width = window.innerWidth;

      if (width <= 768 && this.sidebarStatic) {
        this.toggleSidebar();
        this.changeSidebarActive(null);
      }
    },
  },
  computed: {
    ...mapState(["sidebarClose", "sidebarStatic"]),
  },
  created() {
    const staticSidebar = JSON.parse(localStorage.getItem('sidebarStatic'));

    if (staticSidebar) {
      this.$store.state.layout.sidebarStatic = true;
    } else if (!this.sidebarClose) {
      setTimeout(() => {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      }, 2500);
    }

    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  }
};
</script>

<style src="./Layout.scss" lang="scss"/>
