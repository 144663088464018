import moment from 'moment';

export default {
    namespaced: true,
    state: {
        selectedRow: null,
        // selectedCells: {
        //     from: Number.NaN,
        //     to: Number.NaN
        // }
        selectedCells: [],
        // cellsDataMap: {},
        cellsDataMap: null,
        freeTime: null,
        schedule: null,
        date: null,
        lastFetchFrom: null,
        lastFetchTo: null,
        lastRequestId: null,
        deleteMode: false,
        deleteListMap: {},
        scheduleRefresh: false,
        lessonsRefresh: false,
        isShowClosedLesson: false,
        scheduleHighlight: null
    },
    getters: {
        getScheduleRefresh(state) {
            return state.scheduleRefresh;
        },
        getLessonsRefresh(state) {
            return state.lessonsRefresh;
        },
        getScheduleHighlight(state) {
            return state.scheduleHighlight;
        },
        getIsShowClosedLesson(state) {
            return state.isShowClosedLesson;
        },
        getSelectedCell(state) {
            return state.selectedCells
        },
        getSelectedRow(state) {
            return state.selectedRow;
        },
        getFreeTime(state) {
            return state.freeTime;
        },
        getSchedule(state) {
            return state.schedule;
        },
        getCellsDataMap(state) {
            return state.cellsDataMap;
        },
        getDeleteList(state) {
            return state.deleteListMap;
        },
    },
    mutations: {
        setScheduleRefresh(state){
            state.scheduleRefresh = !state.scheduleRefresh
        },
        setLessonsRefresh(state){
            state.lessonsRefresh = !state.lessonsRefresh
        },
        setScheduleHighlight(state, payload) {
            state.scheduleHighlight = payload
        },
        setIsShowClosedLesson(state){
            state.isShowClosedLesson = !state.isShowClosedLesson
        },
        setSchedule(state, payload) {
            state.schedule = payload;
            state.freeTime = payload.freeTime;
        },
        resetPosition(state) {
            state.selectedRow = null;
            state.selectedCells = [];
            state.cellsDataMap = null;
            state.date = null;
            if (state.deleteMode) {
                state.deleteMode = false;
                state.deleteListMap = {};
            }
        },
        setCell(state, payload) {
            if (state.selectedRow) {
                if (Math.abs(state.selectedCells.to - payload) > 1) {
                    return;
                }
                state.selectedCells.to = payload;
            }
            return;
        },
        setLastFetchDates(state, payload) {
            state.lastFetchTo = payload.to ?? state.lastFetchTo;
            state.lastFetchFrom = payload.from ?? state.lastFetchFrom;
            state.lastRequestId = payload.requestId ?? state.lastRequestId;
        },
        setPosition(state, payload) {
            const findKey = payload.row + '-' + payload.cell;

            if (state.cellsDataMap) {
                if (state.selectedCells.includes(findKey)) {
                    const deleted = state.selectedCells.indexOf(findKey);
                    delete state.cellsDataMap[findKey];
                    state.selectedCells.splice(deleted, 1);
                    if (!state.selectedCells.length) {
                        state.selectedRow = null;
                        state.selectedCells = [];
                        state.cellsDataMap = null;
                        state.date = null;
                        return;
                    }
                } else {
                    state.selectedCells.push(findKey);
                    state.cellsDataMap[findKey] = payload.cellData;
                }
            } else {
                state.cellsDataMap = {};
                state.selectedCells.push(findKey);
                state.cellsDataMap[findKey] = payload.cellData;
            }
        },
    },
    actions: {
        scheduleRefresh({commit}) {
            commit('setScheduleRefresh')
        },
        lessonsRefresh({commit}) {
            commit('setLessonsRefresh')
        },
        showClosedLesson({commit}) {
            commit('setIsShowClosedLesson')
        },
        setScheduleHighlight({ commit }, payload) {
            commit('setScheduleHighlight', payload);
        },
        selectEmptyCell({ commit }, payload) {
            commit('setPosition', payload);
        },
        hoverEmptyCell({ commit }, payload) {
            commit('setCell', payload);
        },
        async fetchSchedule({ commit, state }, { dateFrom, dateTo, requestId, locale }) {
            try {
                const from = dateFrom ?? state.lastFetchFrom;
                const to = dateTo ?? state.lastFetchTo;
                const teacherId = requestId ?? state.lastRequestId;
                // const date_from = dateFrom.format('DD.MM.YYYY');
                // const date_to = dateTo.format('DD.MM.YYYY');
                const { data } = await this.$axios.get(`/api/teacher/${teacherId}/schedule`, {
                    params: {
                        date_from: from.format('DD.MM.YYYY'),
                        date_to: to.format('DD.MM.YYYY'),
                        locale,
                    },
                });
                commit('setLastFetchDates', { from, to, requestId });
                commit('setSchedule', data);
            } catch (e) {
                alert('Произошла ошибка ' + e.message);
            }
        },
        selectForDelete({ state }, payload) {
            const key = payload.row + '-' + payload.cell;
            if (state.deleteListMap[key]) {
                delete state.deleteListMap[key];
                if (!Object.keys(state.deleteListMap).length) {
                    state.deleteMode = false;
                }
                return;
            }
            state.deleteListMap[key] = payload.data;
            state.deleteMode = true;
        },
        formatPayload({ state }, { cellData, date }) {
            const out = [];
            const cells = cellData.sort((a, b) => {
                if (!b || !a) {
                    return 0;
                }
                if (a.from < b.from) {
                    return -1;
                }
                if (a.from > b.from) {
                    return 1;
                }
                return 0;
            });

            let isEnd = false;
            let prev = 0;
            let from = '';
            let to = '';
            let cellPos = 0;

            for (const cell of cells) {
                const data = cell;
                cellPos = parseInt(cell.cellPosition.split('-')[1]);
                if (cellPos - prev === 1 || cellPos - prev === 0 || prev === 0) {
                    // const to =
                    from = from !== '' ? from : data.from;
                    to = data.to;
                    prev = cellPos;
                } else {
                    out.push({
                        // date: state.date,
                        date: date,
                        from: from !== '' ? from : data.from,
                        to: to !== '' ? to : data.to,
                    });
                    prev = cellPos;
                    from = data.from;
                    to = data.to;
                    continue;
                }
                isEnd = true;
            }

            if (from !== '' && out !== '' && isEnd) {
                out.push({
                    date: date,
                    from: from,
                    to: to,
                });
            }
            return out;
        },

        async makePayload({ state, dispatch }) {
            const cells = state.cellsDataMap;
            const formatedCells = {};
            for (const key in cells) {
                if (!formatedCells[cells[key].date]) {
                    formatedCells[cells[key].date] = [
                        {
                            from: cells[key].from,
                            to: cells[key].to,
                            cellPosition: key,
                        },
                    ];
                } else {
                    formatedCells[cells[key].date].push({
                        from: cells[key].from,
                        to: cells[key].to,
                        cellPosition: key,
                    });
                }
            }
            const out = [];
            for (const key in formatedCells) {
                out.push(
                    ...(await dispatch('formatPayload', {
                        date: key,
                        cellData: formatedCells[key],
                    }))
                );
            }
            return out;
        },

        async addSchedule({ dispatch, commit }, { requestId }) {
            try {
                const payload = await dispatch('makePayload');
                const form = new FormData();
                for (const item of payload) {
                    form.append('period[]', item);
                }
                const { data } = await this.$axios.post(`/api/teacher/${requestId}/schedule/add/time`, { period: payload });
                await dispatch('fetchSchedule', { requestId });
                commit('resetPosition');
                return data;
            } catch (e) {
                return e;
            }
        },

        async createFreeTime({ dispatch, commit }, {teacherId, time}) {
            try {
                const { data } = await this.$axios.post(`/api/teacher/${teacherId}/schedule/add/time`, { period: time });
                return data;
            } catch (e) {
                return e;
            }
        },

        async deleteFreeTime({ dispatch, state }, { requestId }) {
            try {
                const payload = {
                    periods: [],
                };
                for (const key in state.deleteListMap) {
                    const item = state.deleteListMap[key];
                    payload.periods.push({
                        period_id: item.data.timeId,
                        time_from: item.caption,
                    });
                }
                const { data } = await this.$axios.post(`/api/teacher/${requestId}/schedule/remove/time/bulk`, payload);
                await dispatch('fetchSchedule', { requestId });
                state.deleteListMap = {};
                state.deleteMode = false;
                return data;
            } catch (e) {
                return e;
            }
        },
    },
};
