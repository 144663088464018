import { Role } from '@/helpers';

export default [
    {
        path: 'salaries',
        name: 'SalaryList',
        component: () => import(/* webpackChunkName: "salaryList" */ '@/pages/Admin/Salaries/SalaryList.vue'),
        meta: {
            sidebar: true,
            authorize: [Role.admin, Role.superadmin],
            icon: 'fa-dollar',
            title: 'Ставки',
        },
    },
    {
        path: 'salaries/create',
        name: 'SalaryCreate',
        component: () => import(/* webpackChunkName: "salaryCreate" */ '@/pages/Admin/Salaries/SalaryCreate.vue'),
        meta: {
            authorize: [Role.admin, Role.superadmin],
        },
    },
    {
        path: 'salaries/:id',
        name: 'SalaryEdit',
        component: () => import(/* webpackChunkName: "salaryEdit" */ '@/pages/Admin/Salaries/SalaryEdit.vue'),
        meta: {
            authorize: [Role.admin, Role.superadmin],
        },
    },
]