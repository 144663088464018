<template>
    <router-view />
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
    name: 'App',
    methods: {
        ...mapMutations({
            setRole: 'user/setRole',
            setAuth: 'user/setAuth',
        }),
    },
    created() {
        this.$i18n.locale = this.$store.state.user.ui_lang;
    }
};
</script>

<style src='./styles/theme.scss' lang='scss' />
