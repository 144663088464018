<template>
  <b-navbar class="header d-print-none app-header">
    <b-nav>
      <b-nav-item>
        <a class="d-md-down-none px-2" href="#" @click="toggleSidebarMethod" id="barsTooltip">
          <i class='fi flaticon-menu' />
        </a>
        <a class="fs-lg d-lg-none" href="#" @click="switchSidebarMethod">
          <i class='fi flaticon-menu' />
        </a>
      </b-nav-item>

    </b-nav>
<!--    <b-nav>-->
<!--      <b-form class="d-sm-down-none ml-5" inline>-->
<!--        <b-form-group>-->
<!--          <b-input-group class="input-group-no-border">-->
<!--            <template v-slot:prepend>-->
<!--              <b-input-group-text><i class='fi flaticon-search-2'/></b-input-group-text>-->
<!--            </template>-->
<!--            <b-form-input id="search-input" placeholder="Search Dashboard" />-->
<!--          </b-input-group>-->
<!--        </b-form-group>-->
<!--      </b-form>-->
<!--    </b-nav>-->

    <b-nav class="ml-auto">
      <b-nav-item-dropdown id="v-step-2" class="settingsDropdown d-sm-down-none" no-caret right>
        <template slot="button-content">
            <i class="fa fa-user-circle" aria-hidden="true" style='color: #5CB0FF;'></i>
<!--          <i class='f flaticon-user px-2' />-->
        </template>
<!--        <b-dropdown-item><i class='fi flaticon-person px-3 mr-3' /> My Account</b-dropdown-item>-->
        <b-dropdown-item-button @click="logout">
<!--          <i class="fi flaticon-power-1 px-3 mr-3" /> Выход-->
            <i class="fa fa-power-off" aria-hidden="true" style='color: #5CB0FF;'></i> Выход
        </b-dropdown-item-button>
      </b-nav-item-dropdown>
    </b-nav>
  </b-navbar>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Notifications from '@/components/Notifications/Notifications';

export default {
  name: 'Header',
  components: { Notifications },
  computed: {
    ...mapState('layout', ['sidebarClose', 'sidebarStatic']),
  },
  methods: {
    ...mapActions('layout', ['toggleSidebar', 'switchSidebar', 'changeSidebarActive', 'setSidebarActive']),
    switchSidebarMethod() {
      if (!this.sidebarClose) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
        this.setSidebarActive(false);
      } else {
        this.switchSidebar(false);
        this.changeSidebarActive(this.$route.name);
        this.setSidebarActive(true);
      }
    },
    toggleSidebarMethod() {
      if (this.sidebarStatic) {
        this.toggleSidebar();
        this.changeSidebarActive(null);
        this.setSidebarActive(false);
      } else {
        this.toggleSidebar();
        this.changeSidebarActive(this.$route.name);
        this.setSidebarActive(true);
      }
    },
    logout() {
      window.localStorage.setItem('authenticated', false);
      this.$router.push('/login');
    },
  }
};
</script>

<style src="./Header.scss" lang="scss"></style>
