import toastError from '@/utils/toast';

export default {
    namespaced: true,
    state: {
        logGroups: [],
        logFile: [],
        logLineDetail: [],
    },
    mutations: {
        setLogGroups(state, payload) {
            state.logGroups = payload;
        },
        setLogFile(state, payload) {
            state.logFile = payload;
        },
        setLogLineDetail(state, payload) {
            state.logLineDetail = payload;
        },
    },
    getters: {
        getLogGroups(state) {
            return state.logGroups;
        },
        getLogFile(state) {
            return state.logFile;
        },
        getLogLineDetail(state) {
            return state.logLineDetail;
        }
    },
    actions: {
        async loadLogGroups({ commit }, params = null) {
            let error_message = 'Не удалось загрузить список логов';

            try {
                const response = await this.$axios.get(`/crm/laravel/logs`);

                if (response) {
                    if (response.data.data) commit('setLogGroups', response.data.data);
                } else {
                    if (response.message) error_message = response.message;

                    toastError(error_message);
                }
            } catch(e) {
                if (e.message) error_message = e.message;

                toastError(error_message);
            }
        },
        async loadLogFile({ commit }, fileName = null) {
            let error_message = 'Не удалось загрузить список логов';

            try {
                const response = await this.$axios.get(`/crm/laravel/logs/${fileName}`);
                if (response) {
                    if (response.data.data) commit('setLogFile', response.data.data);
                } else {
                    if (response.message) error_message = response.message;
                    toastError(error_message);
                }
            } catch(e) {
                if (e.message) error_message = e.message;

                toastError(error_message);
            }
        },
        async loadLogLineDetail({ commit }, data) {
            let error_message = 'Не удалось загрузить список логов';

            try {
                const response = await this.$axios.get(`/crm/laravel/logs/${data.file}/${data.line}`);

                if (response) {
                    if (response.data.data) commit('setLogLineDetail', response.data.data);
                } else {
                    if (response.message) error_message = response.message;
                    toastError(error_message);
                }
            } catch(e) {
                if (e.message) error_message = e.message;

                toastError(error_message);
            }
        },
    }
}