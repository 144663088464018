/**
 * статусы заявок:
 *      0: новая заявка
 *      1: обработанная заявка
 *      2: отклоненная заявка
 * */

export default {
    namespaced: true,
    state: {
        // leads: [],
        leads: null,
        leadsDetails: null,
        suggestionList: null,
        activeTeacher: null,
        activeCustomTeacher: null,
        pageMeta: {
            total: 0,
            per_page: 100
        },
    },
    mutations: {
        setLeads(state, payload) {
            state.leads = payload;
            // state.leads.push(...payload)
        },
        setLeadDetails(state, payload) {
            state.leadsDetails = payload;
        },
        setSuggestionList(state, payload) {
            state.suggestionList = payload;
        },
        setPageMeta(state, payload) {
            state.pageMeta = payload;
        },
        deleteRow(state, index) {
            state.leads.splice(index, 1);
        }
    },
    getters: {
        getLeads(state) {
            return state.leads;
        },
        getLeadDetails(state) {
            return state.leadsDetails;
        },
        getSuggestionList(state) {
            return state.suggestionList || [];
        },
        getPageMeta(state) {
            return state.pageMeta;
        }
    },
    actions: {
        deleteRow({ commit }, index) {
          commit('deleteRow', index)
        },
        async loadLeads({ commit }, params=null) {
            // Вью роутер не понимает что статус нужнго рассматривать как массив поэтому такое решение для кейсов
            // когда в status только одно значение
            if (params && params.status && typeof params.status === 'string') {
                params.status = [parseInt(params.status)];
            }
            const response = await this.$axios.get(`/api/leads`, {params: {...params}});
            commit('setLeads', response.data.data);
            commit('setPageMeta', response.data.meta);
        },
        async loadTrashedLeads({ commit }) {
            const data = await this.$axios.get(`/api/leads?trashed=1`);
            commit('setLeads', data.data.items)
        },
        async findLeads({ commit }, searchWord) {
            const response = await this.$axios.get(`/api/leads`, {
                params: {
                    search: searchWord
                }
            });
            commit('setLeads', response.data.data);
            commit('setPageMeta', response.data.meta);
        },
        async loadLeadDetails({ commit }, leadId) {
            const data = await this.$axios.get(`/api/leads/${leadId}`);
            if (data.status !== 200) {
                commit('setLeadDetails', null);
            }
            commit('setLeadDetails', data.data);
        },
        async deleteRecord({ dispatch }, payload) {
            const data = await this.$axios.post('/api/leads/remove', { id: payload });
            // dispatch('loadLeads');
            return data;
        },

        async cancelPromoLesson({ dispatch }, payload) {
            const response = await this.$axios.post('/api/lessons/cancel-promo', { lead_id: payload });
            return response;
            // if (response.data.status !== 'success') {
            //     todo ?
            // }
        },
        async fetchSuggestionList({ commit }, date) {
            const response = await this.$axios.get('/api/calendar/suggestions', {
                params: {
                    date: date ? date : '',
                }
            });
            commit('setSuggestionList', response.data)
        },

        async restoreLead({ commit }, id) {
            if (typeof id === 'number') {
                await this.$axios.post('/api/leads/restore', {id})
            }
        },

        async cloneLead({ commit }, id) {
            if (typeof id === 'number') {
                const response = await this.$axios.post('/api/leads/clone', {id});
                return response.data;
            }
            return false;
        },

        async updateLeadData({ commit }, payload) {
            const response = await this.$axios.post('/api/leads/update', {
                ...payload
            });
            return response.data
        },

        async createNewLeadAndAccept({ commit }, payload) {
            const response = await this.$axios.post('/crm/lead/create-lead-with-trial-lesson', {
                ...payload
            });
            return response.data;
        },
        async acceptLead({ commit }, payload) {
            const response = await this.$axios.post('/crm/lead/create-trial-lesson', {
                ...payload
            });
            return response.data;
        }
    }
}