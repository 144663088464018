
export default {
    namespaced: true,
    state: {
        // рассписание уроков
        lessonsSchedule: [],
        // свободные "окна" Преподавателей
        availableDaysShedule: [],

        // используются для построения временной шкалы
        dayStartHour: 0, // Начало рабочего дня в (часов)
        dayEndHour: 24, // Конец рабочего дня в (часов)

        periodicLessonCreateSuccessFlag: false,
        periodicLessonRemoveSuccessFlag: false,

        lessonsCalendar: [],
    },
    mutations: {
        setLessonsSchedule(state, data) {
            state.lessonsSchedule = data;
        },
        setAvailableDaysSchedule(state, data) {
            state.availableDaysShedule = data;
        },
        setDayHoursRange(state, range) {
            state.dayStartHour = parseInt(range.day_start_hour);
            state.dayEndHour = parseInt(range.day_end_hour);
        },
        setLessonsCalendar(state, data) {
            state.lessonsCalendar = data;
        },
    },
    getters: {
        getLessonsSchedule(state) {
            return state.lessonsSchedule;
        },
        getAvailableDaysShedule(state) {
            return state.availableDaysShedule;
        },
        getDayHoursRange(state) {
            return { dayStartHour: state.dayStartHour, dayEndHour: state.dayEndHour };
        },
        getLessonsCalendar(state) {
            return state.lessonsCalendar;
        },
    },
    actions: {
        async fetchLessonsSchedule({commit}, params) {
            const response = await this.$axios.get('/api/lessons/schedule', { params });
            if (response.data.status === 'success') {
                if (response.data.day_hours_range) {
                    commit('setDayHoursRange', response.data.day_hours_range);
                }
                commit('setLessonsSchedule', response.data.items);
            } else {
                alert(response.data.error || response.data.status);
                commit('setLessonsSchedule', []);
            }
        },
        async fetchAvailableDaysSchedule({commit}, params) {
            const response = await this.$axios.get('/api/calendar/available-schedule', { params });
            if (response.data.status === 'success') {
                commit('setAvailableDaysSchedule', response.data.items);
            } else {
                alert(response.data.error || response.data.status);
                commit('setAvailableDaysSchedule', []);
            }
        },
        async createLesson({commit}, params) {
            const response = await this.$axios.post('/crm/lessons/create', params);

            return response.data;
        },
        async createTeacherLesson({commit}, params) {
            const response = await this.$axios.post('/crm/lessons/create', params);

            return response.data;
        },
        async updateLessonDetails({commit}, params) {
            const response = await this.$axios.post(`crm/lessons/${params.lesson_id}/update`, params);

            return response.data;
        },
        async updateTeacherLessonDetails({commit}, params) {
            const response = await this.$axios.post(`crm/lessons/${params.lesson_id}/update`, params);

            return response.data;
        },
        async updateLessonStatusDetails({commit}, params) {
            const response = await this.$axios.post('/api/lessons/update-status', params);

            return response.data;
        },
        async removeLesson({commit}, params) {
            const response = await this.$axios.post('/api/lessons/remove', params);

            return response.data;
        },
        async saveCalendarTime({commit}, params) {
            const response = await this.$axios.post('/api/calendar/save', params);

            return response.data;
        },
        async removeCalendarTime({commit}, params) {
            const response = await this.$axios.post('/api/calendar/remove', params);

            return response.data;
        }
    }
}
