<template>
  <li v-if="!childrenLinks && isHeader" :class="{ headerLink: true, className }">
    <router-link :to="{ name: link }" class="sidebar-link">
      <span class="icon">
        <i :class="fullIconName"></i>
      </span>
      {{ $t(header) }}
      <sup v-if="label" :class="'text-' + labelColor" class="headerLabel">
        {{ label }}
      </sup>
      <b-badge v-if="badge" variant="primary" pill>{{ badge }}</b-badge>
    </router-link>
  </li>

  <li v-else-if="childrenLinks" :class="{ headerLink: true, className }">
    <div @click="() => togglePanelCollapse(link)">
      <router-link :to="{name: link}" event="" class="d-flex sidebar-link">
        <span class="icon">
          <i :class="fullIconName"></i>
        </span>
        {{ header }}
        <sup v-if="label" :class="'text-' + labelColor" class="ml-1 headerLabel">
          {{ label }}
        </sup>

        <div :class="{caretWrapper: true, carretActive: isActive}">
          <i class="fa fa-angle-right" />
        </div>
      </router-link>
    </div>

    <b-collapse :id="'collapse' + index" :visible="isActive">
      <ul class="sub-menu">
        <NavLink v-for="item in submenu"
          :header="item.meta.title"
          :index="item.index"
          :link="item.children ? item.children[0].name : item.name"
          :iconName="item.meta.icon"
          :key="item.link"
        />
      </ul>
    </b-collapse>
  </li>

  <li v-else>
    <router-link :to="{ name: link }">
      {{ header }}
      <sup v-if="label" :class="'text-' + labelColor" class="headerLabel">
        {{ label }}
      </sup>
    </router-link>
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'NavLink',
  props: {
    badge: { type: String, default: '' },
    header: { type: String, default: '' },
    iconName: { type: String, default: '' },
    c: { type: String, default: '' },
    headerLink: { type: String, default: '' },
    link: { type: String, default: '' },
    childrenLinks: { type: Array, default: null },
    className: { type: String, default: '' },
    isHeader: { type: Boolean, default: false },
    deep: { type: Number, default: 0 },
    label: { type: String },
    labelColor: { type: String, default: 'warning' },
    index: { type: String },
  },
  methods: {
    ...mapActions('layout', ['changeSidebarActive', 'toggleSidebarActive', 'setSidebarActive']),
    togglePanelCollapse(link) {
      if (this.activeItem.includes(link)) {
        this.toggleSidebarActive();
      } else {
        this.setSidebarActive(true);
      }
      this.changeSidebarActive(link);
    },
  },
  computed: {
    ...mapGetters({
      userRole: 'user/getRole',
      isSubMenuShown: 'layout/sidebarActive',
      activeItem: 'layout/sidebarActiveElement',
    }),
    fullIconName() {
      return `fa ${this.iconName}`;
    },
    submenu: function() {
      const list = this.childrenLinks.filter(item => {
        return (item.meta && item.meta.sidebar && item.meta.authorize && item.meta.authorize.includes(this.userRole));
      });

      return list;
    },
    isActive() {
      if (this.activeItem) {
        return (this.isSubMenuShown && this.activeItem.includes(this.index));
      } else {
        return false;
      }      
    },
  },
};
</script>

<style src="./NavLink.scss" lang="scss" scoped />
