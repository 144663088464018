// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueTouch from 'vue-touch';
import vClickOutside from 'v-click-outside'
import Trend from 'vuetrend';
import Toasted from 'vue-toasted';
import { ObserveVisibility } from 'vue-observe-visibility';
import autoAnimate from "@formkit/auto-animate";
import 'nprogress/nprogress.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import './assets/scss/main.scss';

import store from './store';
import router from './Routes';
import App from './App';
import layoutMixin from './mixins/layout';
import Widget from './components/Widget/Widget';
import axios from 'axios';
import Nprogress from 'nprogress'
import vmodal from 'vue-js-modal';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import moment from 'moment';
import 'moment/locale/ru';
import VueTheMask from 'vue-the-mask';
import I18n from './i18n';
import VueI18n from 'vue-i18n';
import CKEditor from '@ckeditor/ckeditor5-vue2';
// const russian = require('./locales/ru.json')
// const english = require('./locales/en.json')
moment.locale('ru');

Vue.filter('readableSum', function (value) {
  if (!value) return 0;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
});

Vue.use(VueTheMask);
Vue.use(BootstrapVue);
Vue.use(VueTouch);
Vue.use(Trend);
Vue.use(vmodal);
Vue.use(CKEditor);
Vue.use(vClickOutside);

Vue.directive('observe-visibility', ObserveVisibility);
Vue.directive("auto-animate", {
  inserted: function (el, binding) {
    autoAnimate(el, binding.value);
  }
});

const i18n = I18n(Vue, VueI18n)

Vue.component('Widget', Widget);
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

Vue.use(Toasted, {duration: 10000});
Vue.mixin(layoutMixin);

Vue.config.productionTip = false;

// Axios
Vue.prototype.$axios = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}`,
  withCredentials: true
});
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
axios.defaults.withCredentials = true
axios.interceptors.request.use(config => {
  Nprogress.start();
  return config;
});

store.$axios = axios;

axios.interceptors.response.use(response => {
  if (response.data && response.data.route) {
    // TODO push to login page
    // Vue.$router.push({ name: 'Login' });
  }
  // console.log("FROM AXIOS HOOK", response)
  // if (response.data && response.data.errors) {
  //   for (const error_message in response.data.errors) {
  //     Vue.toasted.show(`Ошибка ${response.data.errors[error_message]}`, {
  //       position:'bottom-right',
  //       type: 'error',
  //       action: {
  //         text: 'Закрыть',
  //         onClick: (e, toastObject) => {
  //           toastObject.goAway(0);
  //         }
  //       }
  //     })
  //   }
  // }
  // if (response.data.status === 'success') {
  //   Vue.toasted.show('Запрос успешно выполнен', {
  //     position:'bottom-right',
  //     type: 'success',
  //     action: {
  //       text: 'Закрыть',
  //       onClick: (e, toastObject) => {
  //         toastObject.goAway(0);
  //       }
  //     }
  //   })
  // }
  Nprogress.done()
  return response
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  store,
  router,
  render: h => h(App),
});
