import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Layout from '@/components/Layout/Layout';
import Login from '@/pages/Login/Login';
import ErrorPage from '@/pages/Error/Error';
import { Role } from '@/helpers';
import Nprogress from 'nprogress';
import store from './store';
import leads from './routes/leads';
import students from './routes/students';
import users from './routes/users';
import emailsend from './routes/emailsend';
import teachers from './routes/teachers';
import teacherProfile from './routes/teacherProfile';
import debtors from './routes/debtors';
import lessons from './routes/lessons';
import stats from './routes/stats';
import website from './routes/website';
import settings from './routes/settings';
import smssend from './routes/smssend';
import salaries from './routes/salaries';
import system from "@/routes/system";


console.log(Role)
const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/Login/Login'),
        },
        {
            path: '/login/:token',
            name: 'LoginByToken',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/Login/AccessTokenPage'),
        },
        {
            path: '/error',
            name: 'Error',
            component: ErrorPage,
        },
        {
            path: '/',
            name: 'Layout',
            component: Layout,
            meta: { authorize: [] },
            redirect: '/leads',
            children: [
                ...teacherProfile,
                ...leads,
                ...lessons,
                ...students,
                ...teachers,
                ...salaries,
                // --- transactions ---
                {
                    path: 'transactions',
                    name: 'TransactionList',
                    component: () => import(/* webpackChunkName: "transactionList" */ '@/pages/Admin/Transactions/TransactionList.vue'),
                    meta: {
                        sidebar: true,
                        authorize: [Role.admin, Role.manager, Role.superadmin],
                        icon: 'fa-dollar',
                        title: 'Платежи',
                    },
                },
                {
                    path: 'transactions-create',
                    name: 'TransactionCreate',
                    component: () => import(/* webpackChunkName: "transactionCreate" */ '@/pages/Admin/Transactions/TransactionCreate.vue'),
                    meta: {
                        authorize: [Role.admin, Role.manager, Role.superadmin],
                    },
                },
                {
                    path: 'transactions-edit/:id',
                    name: 'TransactionEdit',
                    component: () => import(/* webpackChunkName: "transactionEdit" */ '@/pages/Admin/Transactions/TransactionEdit.vue'),
                    meta: {
                        authorize: [Role.admin, Role.manager, Role.superadmin],
                    },
                },
                // --- transactions ---

                ...debtors,
                {
                    path: 'old-modules',
                    name: 'old-modules',
                    component: () => import('@/components/PassThrough.vue'),
                    meta: {
                        authorize: [Role.admin, Role.manager, Role.superadmin],
                        sidebar: true,
                        icon: 'fa-archive',
                        title: 'OLD',
                    },
                    children: [
                        {
                            path: 'payments',
                            name: 'PaymentList',
                            component: () => import(/* webpackChunkName: "userDetailPage" */ '@/pages/Admin/PaymentList.vue'),
                            meta: {
                                sidebar: true,
                                authorize: [Role.admin, Role.manager, Role.superadmin],
                                icon: 'fa-dollar',
                                title: 'Платежи OLD',
                            },
                        },
                        {
                            path: 'debtors-old',
                            name: 'DebtorOld',
                            component: () => import('@/pages/Admin/Debtors/DebtorsOld.vue'),
                            meta: {
                                authorize: [Role.admin, Role.manager, Role.superadmin],
                                sidebar: true,
                                icon: 'fa-legal',
                                title: 'Должники OLD',
                            },
                        },
                    ]
                },
                ...smssend,
                ...emailsend,
                ...stats,
                ...website,
                {
                    path: 'products/create',
                    name: 'ProductCreate',
                    component: () => import(/* webpackChunkName: "productCreate" */ '@/pages/Admin/Products/ProductCreate.vue'),
                },
                {
                    path: 'products/:id',
                    name: 'ProductEdit',
                    component: () => import(/* webpackChunkName: "productEdit" */ '@/pages/Admin/Products/ProductEdit.vue'),
                },
                ...users,
                ...settings,
                ...system
            ],
        },
    ],
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
        // Start the route progress bar.
        Nprogress.start();
    }
    next();
});

router.beforeEach(async (to, from, next) => {
    const { authorize } = to.meta;
    let currentUser = store.getters['user/getAuth'];
    if (!currentUser) {
        currentUser = await store.dispatch('user/initUser');
    }
    if (authorize) {
        if (!currentUser) {
            return next({ path: '/login', query: { returnUrl: to.path } });
        }
        if (authorize.length && !authorize.includes(currentUser.role)) {
            switch (currentUser.role) {
                case Role.teacher: {
                    return next({ path: '/mylessons' });
                }
                case Role.contentManager: {
                    return next({ path: '/website/article' });
                }
                default: {
                    return next({ path: '/leads' });
                }
            }
        }
    }
    next();
});

router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    Nprogress.done();
});

export default router;
