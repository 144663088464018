import { Role } from '@/helpers';

export default [
    {
        path: 'leads',
        name: 'LeadsPage',
        component: () => import(/* webpackChunkName: "login" */ '@/pages/Admin/LeadsList'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            sidebar: true,
            icon: 'fa-file-text',
            title: 'Заявки',
        },
    },
    {
        path: 'leads/:detail',
        name: 'LeadsDetail',
        component: () => import(/* webpackChunkName: "login" */ '@/pages/Admin/LeadsDetail'),
        // component: () => import(/* webpackChunkName: "login" */ '@/pages/Admin/LeadsDetailNew'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            sidebar: false,
            icon: 'flaticon-info',
            title: 'Детали заявки',
        },
    },
    {
        path: 'lead/new',
        name: 'NewLead',
        component: () => import(/* webpackChunkName: "login" */ '@/pages/Admin/LeadsNew'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            sidebar: false,
            icon: 'flaticon-info',
            title: 'Новая заявка',
        },
    },
]