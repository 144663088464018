
export default {
    namespaced: true,
    state: {
        lessons: null, // [{}]
        teacherList: [],
        teachersOptionsList: [],
        teacherUserDetail: [],
        acessLink: null,
    },
    mutations: {
        setLessons(state, data){
            state.lessons = data
        },
        setTeacherList(state, data){
            state.teacherList = data
        },
        setTeachersOptionsList(state, data){
            state.teachersOptionsList = data;
        },
        setTeacherUserDetail(state, data){
            state.teacherUserDetail = data
        },
        setAccessLink(state, data) {
            state.acessLink = data
        }
    },
    getters: {
        getLessons(state){

        },
        getTeacherList(state){
            return state.teacherList;
        },
        getTeachersOptionsList(state){
            return state.teachersOptionsList;
        },
        getTeacherUserDetail(state){
            return state.teacherUserDetail;
        },
        getAccessLink(state) {
            return state.acessLink
        }
    },
    actions: {
        async fetchTeachersOptions({ commit }, payload) {
            const response = await this.$axios.get('/api/teachers/short', {
                params: payload
            });
            commit('setTeachersOptionsList', response.data.items);
        },
        async fetchTeacherList({ commit }) {
            const response = await this.$axios.get('/api/teachers');
            commit('setTeacherList', response.data.items)
        },
        async fetchTeacherDetails({ commit }, id) {
            const response = await this.$axios.get(`/api/teacher/${id}`);
            commit('setTeacherUserDetail', response.data);
            return response.data;
        },
        async sendAccessLink({ commit }, id) {
            const response = await this.$axios.get(`/api/teacher/${id}/send-link`);
            return response.data;
        },
        async fetchAccessLink({ commit }, id) {
            const result = await this.$axios.get(`/api/teacher/${id}/access-link`);
            commit('setAccessLink', result.data.access_link)
        },
        async updateTeacherDetails({ commit }, _data) {
            const response = await this.$axios.post(`/api/teacher/update`, { ..._data });
            if (response.data.status === 'error') {
                let errors = '';
                Object.keys(response.data.errors).map(key => {
                    errors += `${key}: ${response.data.errors[key]}\n`;
                });
                throw Error(errors);
            }
            return { status: response.status };
        },
        async createTeacher({ commit }, _data) {
            const response = await this.$axios.post(`/api/teacher/create`, { ..._data });
            if (response.data.status === 'error') {
                let errors = '';
                Object.keys(response.data.errors).map(key => {
                    errors += `${key}: ${response.data.errors[key]}\n`;
                });
                throw Error(errors);
            }
            return { status: response.data.status };
        },
        async findTeachers({ commit }, {searchWord, statuses}) {
            const response = await this.$axios.get(`/api/teachers`, {
                params: {
                    search: searchWord,
                    statuses: statuses.map((v) => {return v.key})
                }
            });
            commit('setTeacherList', response.data.items);
        },
        async deleteRecord({ dispatch }, id) {
            const response = await this.$axios.post('/api/teacher/remove', { id });
            dispatch('fetchTeacherList');
            return response.data;
        },
    }
}