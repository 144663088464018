<template>
    <div class="d-flex flex-wrap p-5 h-100 align-items-center">
        <div class="row">
            <h4 class="col-12 mb-3 text-center">{{ title }}</h4>
            <h5 class="col-12 mb-4 text-center">{{ recordName }}</h5>
            <h5 v-if="extraInfo" class="col-12 mb-4 text-center">({{ extraInfo }})</h5>
            <h5 v-if="extraInfo2" class="col-12 mb-4 text-center">({{ extraInfo2 }})</h5>
            <div class="col-12 d-flex justify-content-center">
                <button class="px-5 btn alert-danger mr-2" @click.prevent="deleteRecord">Да</button>
                <button class="px-5 btn btn-primary" @click.prevent="closeModal">Нет</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DeleteModal',
        props: {
            recordId: {
                type: [Number, String],
                required: true
            },
            title: {
                default: 'Вы действительно хотите удалить?'
            },
            recordName: {
                type: [Number, String],
                required: false
            },
            extraInfo: {
                type: [String],
                required: false
            },
            extraInfo2: {
                type: [String],
                required: false
            },
            deleteHandler: {
                type: [Function],
                required: true
            },
            callback: {
              type: [Function],
              required: false
            },
        },
        methods: {
            async deleteRecord() {
                await this.deleteHandler(this.recordId);
                this.$modal.hide('DeleteModal');
                if (this.callback) {
                    await this.callback()
                }
            },
            closeModal() {
                this.$modal.hide('DeleteModal');
            }
        },
    }
</script>

<style scoped>

</style>
