import { BehaviorSubject } from 'rxjs';
import { requestOptions, handleResponse } from '@/helpers';
import axios from 'axios'
import {mapActions} from 'vuex'
import '../../store/common'



const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));


function login(username, password) {
    return axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/login`, {
        login: username,
        password
    }, {
        withCredentials: true
    }).then(response => {
        if (response.data.status && response.data.status === 'success') {
            if (response.data.constants) {
                localStorage.setItem('experience', response.data.constants.experience);
            }
        }
        return response;
    })
}

function validateToken(token) {
    return axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/login/${token}`, {
        withCredentials: true
    }).then(resolve => {
        localStorage.setItem('experience', resolve.data.constants.experience);
        return resolve;
    })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}

export const authenticationService = {
    login,
    logout,
    validateToken,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};
