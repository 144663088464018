import { Role } from '@/helpers';

export default [
    {
        path: 'system',
        name: 'System',
        component: () => import('@/components/PassThrough.vue'),
        meta: {
            authorize: [Role.superadmin],
            sidebar: true,
            icon: 'fa-cog',
            title: 'Система',
        },
        children: [
            {
                path: 'logs',
                name: 'SystemPassThrough',
                component: () => import('@/components/PassThrough.vue'),
                meta: {
                    authorize: [Role.superadmin],
                    sidebar: true,
                    icon: 'fa-cog',
                    title: 'Логи',
                },
                children: [
                    {
                        path: '',
                        name: 'Logs',
                        component: () => import('@/pages/Admin/System/Logs/LogsComponent.vue'),
                        meta: {
                            authorize: [Role.superadmin],
                            title: 'Логи',
                        },
                    }
                ],
            },
        ],
    },
]