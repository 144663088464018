<template>
  <div class="sidebar-wrapper">
    <nav
        :class="{sidebar: true, sidebarStatic, sidebarOpened}"
        style="height: 100%"
        @mouseenter="sidebarMouseEnter"
        @mouseleave="sidebarMouseLeave"
    >
      <header class="logo">
        <router-link to="/"><span class="primary-word">Chess</span> <span class="secondary-word">CRM</span>
        </router-link>
      </header>
      <h5 class="navTitle first">
        {{userName}} ({{userRole}})
      </h5>
      <ul class="nav">
        <NavLink
            v-for="item in menuItems"
            :header="item.meta.title"
            :link="item.name"
            :childrenLinks="item.children"
            :iconName="item.meta.icon"
            :index="item.name"
            isHeader
            :key="item.path"
        />
      </ul>
    </nav>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import isScreen from '@/core/screenHelper';
import NavLink from './NavLink/NavLink';

export default {
  name: 'Sidebar',
  components: {NavLink},
  data() {
    return {
      alerts: [
        {
          id: 0,
          title: 'Sales Report',
          value: 15,
          footer: 'Calculating x-axis bias... 65%',
          color: 'danger',
        },
        {
          id: 1,
          title: 'Personal Responsibility',
          value: 20,
          footer: 'Provide required notes',
          color: 'primary',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      sidebarStatic: 'layout/sidebarStatic',
      sidebarOpened: 'layout/sidebarClose',
      userRole: 'user/getRole',
      userName: 'user/getName',
    }),
    menuItems: function() {
      const mainRoute = this.$router.options.routes.filter(route => (route.path === '/'));
      const routes = mainRoute[0].children.filter(item => {
        return (item.meta && item.meta.sidebar && item.meta.authorize && item.meta.authorize.includes(this.userRole));
      });
      return routes;
    }
  },
  methods: {
    ...mapActions('layout', ['changeSidebarActive', 'switchSidebar', 'setSidebarActive']),
    setActiveByRoute() {
      // const paths = this.$route.fullPath.split('/');
      // paths.pop();
      this.changeSidebarActive(this.$route.name);
    },
    sidebarMouseEnter() {
      if (!this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.switchSidebar(false);
        this.setActiveByRoute();
        this.changeSidebarActive(this.$route.name);
        this.setSidebarActive(true);
      }
    },
    sidebarMouseLeave() {
      if (!this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
        this.setSidebarActive(false);
      }
    },
  },
  created() {
    this.setActiveByRoute();
  },

};
</script>

<!-- Sidebar styles should be scoped -->
<style src="./Sidebar.scss" lang="scss" scoped/>
