import { Role } from '@/helpers';

export default [
    {
        path: 'lessons',
        name: 'AdminLessons',
        component: () => import(/* webpackChunkName: "login" */ '@/pages/Admin/LessonsCalendar'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            sidebar: true,
            icon: 'fa-calendar-plus-o',
            title: 'Занятия',
        },
    },
]