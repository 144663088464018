import i18n from '../i18n';

export default {
    namespaced: true,
    state: {
        role: null,
        name: null,
        status: null,
        isAuth: null,
        user_id: null,
        teacher_profile: null,
        refresh_timer: null,
        ui_lang: 'ru',
    },
    mutations: {
        setRole(state, data) {
            state.role = data;
        },
        setStatus(state, data) {
            state.status = data;
        },
        setName(state, data) {
            state.status = data;
        },
        setAuth(state, data) {
            state.isAuth = data ? data : true;
        },
        setUserId(state, data) {
            state.user_id = data;
        },
        setTeacherProfile(state, data) {
            const langMap = {
                0: 'ru',
                1: 'ru',
                2: 'en',
            };
            state.teacher_profile = data;
            state.ui_lang = langMap[data.language];
            i18n().locale = state.ui_lang;
        },
        setRefreshTimer(state, data) {
            state.refresh_timer = data;
        },
    },
    getters: {
        getRole(state) {
            return state.role;
        },
        getStatus(state) {
            return state.status;
        },
        getName(state) {
            return state.status;
        },
        getTeacherProfile(state) {
            return state.teacher_profile;
        },
        getAuth(state) {
            if (!state.isAuth && !state.role) {
                return null;
            }
            return {
                isAuth: state.isAuth,
                role: state.role,
            };
        },
        getUserId(state) {
            return state.user_id;
        },
        getTeachingLang(state) {
            return state.ui_lang;
        },
    },
    actions: {
        async initUser({ dispatch, commit, getters }) {
            const response = await this.$axios.get(`${process.env.VUE_APP_API_BASE_URL}/api`, { withCredentials: true });
            if (response.data) {
                if (response.data.route !== '/login' && !!response.data?.user_role) {
                    commit('setAuth', true);
                    commit('setRole', response.data.user_role);
                    commit('setName', response.data.user_name);
                    // commit('setRefreshTimer', setTimeout(() => {
                    //     dispatch('initUser')
                    // }, 1000 * 60 * 10))     // Таймер для обновления токена
                    if (response.data.user_role === 'teacher') {
                        await dispatch('fetchTeacherProfile', response.data.user_id);
                    }
                }
                if (response.data.constants) {
                    commit('common/setConstants', response.data.constants, { root: true });
                }
            }
            return getters.getAuth;
        },
        async fetchTeacherProfile({ commit }, id) {
            const response = await this.$axios.get(`/api/teacher/${id}`);
            commit('setTeacherProfile', response.data);
            commit('setUserId', response.data.id);
        },
        async updateTeacherProfile({ commit }, data) {
            const response = await this.$axios.post('/api/teacher/update-profile', data);
            return response.data;
        },
    },
};

// Обновление пользоватиеля отсылать все поля
