import { Role } from '@/helpers';

export default [
    {
        path: 'emailsend',
        name: 'EmailSend',
        component: () => import('@/pages/Admin/EmailSend.vue'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            sidebar: true,
            icon: 'fa-envelope-o',
            title: 'Рассылка Почты',
        },
    },
    {
        path: 'emailsend/message/create',
        name: 'EmailCreate',
        component: () => import('@/pages/Admin/EmailCreate.vue'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            sidebar: false,
            icon: 'fa-envelope-o',
            title: 'Рассылка Почты',
        },
    },
    {
        path: 'emailsend/message/:id',
        name: 'EmailEdit',
        component: () => import('@/pages/Admin/EmailEdit.vue'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            sidebar: false,
            icon: 'fa-envelope-o',
            title: 'Рассылка Почты',
        },
    },
    {
        path: 'emailsend/campaign/create',
        name: 'EmailCampaignCreate',
        component: () => import('@/pages/Admin/EmailCampaignCreate.vue'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            sidebar: false,
            icon: 'fa-envelope-o',
            title: 'Рассылка Почты',
        },
    },
    {
        path: 'emailsend/campaign/show/:id',
        name: 'EmailCampaignView',
        component: () => import('@/pages/Admin/EmailDetails.vue'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            sidebar: false,
            icon: 'fa-envelope-o',
            title: 'Рассылка Почты',
        },
    },
]