import reviewsList from '../pages/Admin/ReviewsList';

export default {
    namespaced: true,
    state: {
        reviewsList: [],
        pageMeta: null,
    },
    mutations: {},
    getters: {
        getList(state) {
            return state.reviewsList;
        },
        getPageMeta(state) {
            return state.pageMeta
        }
    },
    actions: {
        async loadList({state}, params = {}) {
            try {
                const {data} = await this.$axios.get("/api/reviews", {params: {...params}});
                if (data && data.data) {
                    state.reviewsList = data.data
                    state.pageMeta = data.meta
                }
            } catch (e) {
                this.$toasted.error("Произошла ошибка во время подгрузки данных")
            }
        },
        async deleteRecord({dispatch}, id) {
            try {
                const { data } = await this.$axios.delete(`/api/reviews/${id}`)
                if (data.success) {
                    return
                }
                // this.$toasted.success('запись удалена')
                // dispatch('loadStudentList')
            } catch (e) {
                alert('ошибка удаления')
            }
        },
    },
};