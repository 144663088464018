import { Role } from '@/helpers';

export default [
    {
        path: 'smssend',
        name: 'SmsSend',
        component: () => import('@/pages/Admin/SmsSend.vue'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            sidebar: true,
            icon: 'fa-whatsapp',
            title: 'Whatshapp',
        },
    },
    {
        path: 'smssend/create',
        name: 'SmsSendAdd',
        component: () => import('@/pages/Admin/SmsSendAdd.vue'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            icon: 'fa-whatsapp',
            title: 'Whatshapp',
        },
    },
    {
        path: 'smssend/:id',
        name: 'SmsSendDetail',
        component: () => import('@/pages/Admin/SmsSendDetail.vue'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            icon: 'fa-whatsapp',
            title: 'Whatshapp',
        },
    },
]