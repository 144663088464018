/**
 * Стейт для общих настроек, выпадающие списки и т.д
 * */

export default {
    namespaced: true,
    state: {
        usersList: null,
        userDetail: null
    },
    mutations: {
        setUsersList(state, data) {
            state.usersList = data
        },
        setUserDetail(state, data) {
            state.userDetail = data
        }
    },
    getters: {
        getUsersList(state) {
            return state.usersList
        },
        getUsersDetails(state) {
            return state.userDetail
        }
    },
    actions: {
        async fetchUserList({commit}) {
            const response = await this.$axios.get('/api/users');
            commit('setUsersList', response.data.items)
        },
        async fetchUserData({commit}, id) {
            const response = await this.$axios.get(`/api/users/${id}`);
            commit('setUserDetail', response.data)
        },
        async updateUserData({commit}, _data) {
            const response = await this.$axios.post(`/api/users/store`, {..._data});
            if (response.data.status === 'error') {
                return { status: response.data.status, errors: response.data.errors };
            }
            return { status: response.data.status };
        },
        async deleteRecord({dispatch}, id) {
            const response = await this.$axios.post('/api/users/remove', { id });
            dispatch('fetchUserList');
            return { status: response.status };
        },
    }
}