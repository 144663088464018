import moment from "moment/moment";

export default {
    namespaced: true,
    state: {
        filter: {
            date_from: null,
            date_to: null,
            teachers_ids: [], // список выбранных преподавателей
            students_ids: [], // список выбранных учеников
            gender: null,
            language: null,
        },
        lessons: [],
        availableTime: [],
        isShowCanceledLesson: false,
    },
    getters: {
        getLessons(state) {
            return state.lessons;
        },
        getAvailableTime(state) {
            return state.availableTime;
        },
        getFilters(state) {
            return state.filter;
        },
        getSelectedTeachers(state) {
            return state.filter.teachers_ids;
        },
        getSelectedStudents(state) {
            return state.filter.students_ids;
        },
    },
    mutations: {
        setLessons(state, data) {
            state.lessons = data;
        },
        setAvailableTime(state, data) {
            state.availableTime = data;
        },
        setFilterByKey(state, payload) {
            state.filter[payload.key] = payload.value;
        },
    },
    actions: {
        async updateFilterByKey({dispatch, commit, state}, payload) {
            await commit('setFilterByKey', payload);
            await dispatch('loadLessons');
        },
        async fetchLessons({commit, state}) {
            const {data} = await this.$axios.post('/crm/lessons/search', state.filter);
            if (data.data) {
                commit('setLessons', data.data);
            } else {
                commit('setLessons', []);
            }
        },
        async fetchAvailableTime({commit, state}) {
            const {data} = await this.$axios.post('/crm/teacher-calendar/search', state.filter);
            if (data.data) {
                commit('setAvailableTime', data.data);
            } else {
                commit('setAvailableTime', []);
            }
        },
        async loadLessons({dispatch}) {
            await Promise.all([
                await dispatch('fetchLessons'),
                await dispatch('fetchAvailableTime')
            ]);
        }
    }
}
