import { Role } from '@/helpers';

export default [
    {
        path: 'students',
        name: 'StudentList',
        component: () => import(/* webpackChunkName: "studentList" */ '@/pages/Admin/Students/StudentsList.vue'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            sidebar: true,
            icon: 'fa-graduation-cap',
            title: 'Ученики',
        },
    },
    {
        path: 'students/add',
        name: 'StudentAdd',
        component: () => import(/* webpackChunkName: "studentList" */ '@/pages/Admin/Students/StudentCreate.vue'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            icon: 'flaticon-diamond',
            title: 'Добавить Ученика',
        },
    },
    {
        path: 'students/:id',
        name: 'StudentDetail',
        component: () => import(/* webpackChunkName: "studentList" */ '@/pages/Admin/Students/StudentDetail.vue'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            icon: 'flaticon-diamond',
            title: 'Детали ученика',
        },
    },
    {
        path: 'students/:id/show',
        name: 'ShowStudent',
        component: () => import(/* webpackChunkName: "studentList" */ '@/pages/Admin/Students/ShowStudent.vue'),
        meta: {
            authorize: [Role.teacher, Role.superadmin],
            icon: 'flaticon-diamond',
            title: 'Просмотр ученика',
        },
    },
]