
export default {
    namespaced: true,
    state: {
        settingsList: {
            lesson_duration: '25,45,60,75',
            day_start_hour: 6,
            day_end_hour: 24,
        },
        locationsList: [
            { value: 'Russia', text: 'Россия' },
            { value: 'Europe', text: 'Европа' },
            { value: 'Kazakhstan', text: 'Казахстан' }
        ]
    },
    mutations: {
        setSettingList(state, payload) {
            state.settingsList = payload
        },
        setLocationsList(state, payload) {
            state.locationsList = payload
        },
    },
    getters: {
        getSettingsList(state) {
            return state.settingsList;
        },
        getLocationsList(state) {
            return state.locationsList;
        },
        getSettingsWorkingHours(state) {
            return {
                start: state.settingsList.day_start_hour,
                end: state.settingsList.day_end_hour
            };
        }
    },
    actions: {
        async fetchSettingsList({commit}) {
            const response = await this.$axios.get('/crm/settings');
            commit('setSettingList', response.data.items);
            commit('setLocationsList', response.data.locations);
            return response.data.items;
        },
        async updateSettings({ commit }, data) {
            await this.$axios.post('/crm/settings/update', data);
        }
    }
}