import toastError from '@/utils/toast';

export default {
    namespaced: true,
    state: {
        blogArticles: null,
        blogArticleDetail: null,
        pageMeta: {
            total: 0,
            per_page: 100
        },
    },
    mutations: {
        setBlogArticles(state, payload) {
            state.blogArticles = payload;
        },
        setBlogArticleDetail(state, payload) {
            state.blogArticleDetail = payload;
        },
        setPageMeta(state, payload) {
            state.pageMeta = payload;
        },
        deleteRow(state, index) {
            state.blogArticles.splice(index, 1);
        }
    },
    getters: {
        getBlogArticles(state) {
            return state.blogArticles;
        },
        getBlogArticleDetail(state) {
            return state.blogArticleDetail;
        },
        getPageMeta(state) {
            return state.pageMeta;
        }
    },
    actions: {
        deleteRow({ commit }, index) {
            commit('deleteRow', index)
        },
        async loadBlogArticles({ commit }, params = null) {
            if (params && params.status && typeof params.status === 'string') {
                params.status = [parseInt(params.status)];
            }

            let error_message = 'Не удалось загрузить статьи блога';

            try {
                const response = await this.$axios.get(`/crm/blog-articles`, { params: {...params} });

                if (response) {
                    if (response.data.data) commit('setBlogArticles', response.data.data);
                    if (response.data.meta) commit('setPageMeta', response.data.meta);
                } else {
                    if (response.data.message) error_message = response.data.message;

                    toastError(error_message);
                }
                
            } catch(e) {
                if (e.message) error_message = e.message;

                toastError(error_message);
            }
        },
        async findBlogArticles({ commit }, searchWord) {
            let error_message = 'Не удалось найти статьи блога';

            try {
                const response = await this.$axios.get(`/crm/blog-articles`, {
                    params: {
                        search: searchWord,
                    }
                });

                if (response.data.success) {
                    if (response.data.data) commit('setBlogArticles', response.data.data);
                    if (response.data.meta) commit('setPageMeta', response.data.meta);
                } else {
                    if (response.data.message) error_message = response.data.message;

                    toastError(error_message);
                }
                
            } catch(e) {
                if (e.message) error_message = e.message;

                toastError(error_message);
            }
        },
        async loadBlogArticleDetail({ commit }, articleId) {
            let error_message = 'Не удалось загрузить статью блога';

            try {
                const response = await this.$axios.get(`/crm/blog-articles/${articleId}`);

                if (response) {
                    if (response.data.data) commit('setBlogArticleDetail', response.data);
                } else {
                    commit('setBlogArticleDetail', null);
                }
                
            } catch(e) {
                if (e.message) error_message = e.message;

                toastError(error_message);
            }
        },
        async deleteBlogArticle({ dispatch }, articleId) {
            let error_message = 'Не удалось удалить статью блога';

            try {
                const response = await this.$axios.delete(`/crm/blog-articles/${articleId}`);
                return response;
            } catch(e) {
                if (e.message) error_message = e.message;

                toastError(error_message);
            }
        },
    }
}