<template>
    <div>
        <h4 class='mb-4 page-title'>Отзывы</h4>
        <div class='filter__container'>
            <div class='row mb-3 justify-content-between'>
                <div class='input-group col-4'>
                    <input type='search' class='form-control' @keyup='searchByEnter' v-model='searchWord'
                           id='search-input'>
                    <span class='input-group-append'>
              <button type='button' class='btn btn-default'>Поиск</button>
            </span>
                </div>
                <div class='col-4 text-right'>
                    <button class='button_add' @click.prevent='$router.push({name: "ReviewCreate"})'>Добавить отзыв</button>
                </div>
            </div>
            <div class='input-group col-4'>

            </div>
        </div>

        <div class='widget'>

            <table class='table table-hover'>
                <thead class='table_head'>
                <tr>
                    <th>Локация</th>
                    <th>Переподаватель</th>
                    <th>Клиент</th>
                    <th>Возраст ребенка</th>
                    <th class='header-profile'>оценка</th>
                    <th class='text-center action'>отзыв</th>
                    <th class='text-center action'>дата/время</th>
                    <th class='text-center action'>опубликовано?</th>
                    <th class='text-center action'>на главной?</th>
                    <th class='text-center action'>действия</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for='item in getList'
                    :key='item.id'
                >
                    <td>{{ item.location }}</td>
                    <td>{{ item.teacher }}</td>
                    <td>{{ item.customer }}</td>
                    <td>{{ item.child_age }}</td>
                    <td>{{ item.mark }}</td>
                    <td style='width: 40%'>{{ item.review }}</td>
                    <td>{{ item.created_at }}</td>
                    <td><span :class='getClass(item.published)'>{{ item.published }}</span></td>
                    <td><span :class='getClass(item.is_published_in_main)'>{{ item.is_published_in_main }}</span></td>
                    <td class='actions_container'>
                        <router-link class='leads_table_action_link btn mr-2 btn-primary'
                                     :to="{name: 'ReviewDetail', params: {id: item.id}}">
                            <span class='glyphicon glyphicon-edit' />
                        </router-link>
                        <a class='btn mr-2 btn-danger' @click.prevent='deleteRow(item.id, item.customer)'>
                            <span class='fa fa-trash-o delete'
                            ></span>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class='overflow-auto'>
            <b-pagination class='paginator'
                          v-model='currentPage'
                          :total-rows='getPageMeta.total'
                          :per-page='getPageMeta.per_page'
                          aria-controls='my-table'
            ></b-pagination>
        </div>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DeleteModal from '../../components/Modal/DeleteModal';
import { debounce } from 'debounce';

export default {
    name: 'ReviewsList',
    data() {
        return {
            searchWord: this.$route.query.search || '',
            routerParams: { ...this.$route.query },
            currentPage: this.$route.query.page || 1,
        };
    },
    computed: {
        ...mapGetters({
            getList: 'reviews/getList',
            getPageMeta: 'reviews/getPageMeta',
        }),
    },
    watch: {
        searchWord: debounce(async function(newVal, oldVal) {
            if (!newVal || newVal === '') {
                delete this.routerParams['find'];
                this.updateRouter();
                this.loadList(this.$route.query);
                return;
            }
            this.routerParams['find'] = newVal;
            this.updateRouter();
            await this.loadList(this.$route.query);
        }, 500),
        currentPage(value) {
            this.routerParams['page'] = value;
            this.updateRouter();
            this.loadList(this.$route.query);
        },
    },
    methods: {
        ...mapActions({
            loadList: 'reviews/loadList',
            deleteRecord: 'reviews/deleteRecord',
        }),
        updateRouter() {
            this.$router.push({ name: 'ReviewsList', query: this.routerParams });
        },
        getClass(status) {
            if (status === 'неопубликовано') return 'badge-noprofile';
            if (status === 'опубликовано') return 'badge-published';
        },
        deleteRow(id, name) {
            this.$modal.show(
                DeleteModal,
                {
                    recordId: id,
                    recordName: name,
                    deleteHandler: this.deleteRecord,
                    callback: async () => {
                        await this.loadList(this.$route.query);
                        this.$toasted.success('запись удалена', { position: 'bottom-right' });
                    },
                }, { name: 'DeleteModal' },
            );
        },
        async searchByEnter(e) {
            if (this.searchWord.length > 2) {
                if (e.keyCode == 13) {
                    // await this.loadList(this.searchWord);
                }
            }
        },
        async search() {
            if (this.searchWord.length > 2) {
                // await this.loadList(this.searchWord);
            }
        },
    },
    async created() {
        await this.loadList();
    },
};
</script>

<style lang='scss' scoped>
.btn .glyphicon {
    top: 0 !important;
}

.actions_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.action {
    width: 100px;
}

.header-profile {
    width: 120px;
    text-align: center;
}

.button_add {
    display: inline-block;
    vertical-align: middle;
    border: none;
    font-weight: 400;
    background: #3E86CA;
    color: #fff;
    border-radius: 0.5rem;
    padding: 0 20px;
    font-size: 14px;
    height: 100%;
    transition: .1s;

    &:hover {
        background: #2e6ca7;
        text-decoration: none;
    }
}

@mixin badge($bg, $col) {
    background: $bg;
    color: $col;
    padding: 3px 8px;
    font-weight: 600;
    font-size: 10px;
    width: 100%;
    display: block;
    text-align: center;
    border-radius: 5px;
}

.badge {
    &-noprofile {
        @include badge(#ee8c62, #fff);
    }

    &-published {
        @include badge(#70dba6, #000);
    }

    &-edit {
        @include badge(#faed5e, #000);
    }
}

.page-title {
    font-weight: bold;
}
.paginator {
    ::v-deep {
        li {
            box-shadow: 0px 0px 7px -4px #00000044;
        }
    }
}
</style>