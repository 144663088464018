import { Role } from '@/helpers';

export default [
    {
        path: 'debtors',
        name: 'Debtor',
        component: () => import('@/pages/Admin/Debtors/Debtors.vue'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            sidebar: true,
            icon: 'fa-legal',
            title: 'Должники',
        },
    }
]